function getOuterHeight(element) {
    let height = element.offsetHeight
    const style = getComputedStyle(element)

    height += parseInt(style.marginTop) + parseInt(style.marginBottom)

    return height
}

function getInnerWidth(element) {
    let width = element.clientWidth
    const style = getComputedStyle(element)

    width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)

    return width
}

function getOuterWidth(element) {
    let width = element.offsetWidth
    const style = getComputedStyle(element)

    width += parseInt(style.marginLeft) + parseInt(style.marginRight)

    return width
}

export { getOuterHeight, getInnerWidth, getOuterWidth }

import React, { Component } from "react"

// Libs
import anime from "animejs/lib/anime.es.js"

// Styles
import Styles from "./LoaderIntro.module.styl"

// Helpers
import getDimensions from "~helpers/dimensions"

class LoaderIntro extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.fullLoader = React.createRef()
        this.circleSVG = React.createRef()
    }

    state = {
        dashArray: 0,
        dashOffset: 0,
    }

    componentDidMount() {
        const circleRadius = getDimensions(this.circleSVG.current).width,
            computedStrokeDashArray = (2 * Math.PI * circleRadius) / 2

        this.setState(
            {
                dashArray: computedStrokeDashArray,
                dashOffset: computedStrokeDashArray,
            },
            () => {
                this.setAnimation()
            }
        )
    }

    setAnimation() {
        let startValues = {
            dashOffset: this.state.dashOffset,
        }

        anime.set(this.circleSVG.current, {
            opacity: 1,
        })

        let timeline = anime.timeline({
            easing: "easeOutExpo",
            autoplay: false,
            delay: 1000,
            complete: (anim) => {
                this.props.callback()
            },
        })

        timeline.add({
            targets: startValues,
            dashOffset: 0,
            duration: 5000,
            easing: "easeInOutQuad",
            update: (anim) => {
                this.setState({
                    dashOffset: startValues.dashOffset,
                })
            },
        })

        timeline.add(
            {
                targets: this.fullLoader.current,
                opacity: [1, 0],
                easing: "easeOutQuad",
                duration: 1000,
            },
            5500
        )

        timeline.play()
    }

    render() {
        return (
            <div className={Styles.LoaderIntro} ref={this.fullLoader}>
                <div className={Styles.LoaderIntro__inner}>
                    <div className={Styles.LoaderIntro__circle}>
                        <svg viewBox="0 0 48 48" width="100%" height="100%">
                            <ellipse
                                ref={this.circleSVG}
                                className={Styles.LoaderIntro__circle__item}
                                vectorEffect="non-scaling-stroke"
                                fill="none"
                                strokeWidth="1"
                                stroke="#000"
                                cx="24"
                                cy="24"
                                rx="23"
                                ry="23"
                                opacity="0"
                                strokeDasharray={this.state.dashArray}
                                strokeDashoffset={this.state.dashOffset}
                            />
                        </svg>
                    </div>
                    <div className={Styles.LoaderIntro__body}>
                        <img
                            src={"/logo-spintank.svg"}
                            className={Styles.LoaderIntro__logo}
                        />
                        <span className={`teasing-1`}>Présente</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoaderIntro

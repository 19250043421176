import React, { Component } from "react"
import { Link } from "gatsby"

// Layout
import { Row, Cell, View, Panel, Footer } from "~layout/Layout"

// Components
import LoaderIntro from "~components/Loaders/LoaderIntro"
import PanelTitle from "~components/Panels/Home/PanelTitle"
import PanelLaunch from "~components/Panels/Home/PanelLaunch"
import PanelWhy from "~components/Panels/Home/PanelWhy"
import CanvasBackground from "~components/Canvases/CanvasBackground"
import Metas from "~misc/Metas"

// Libs
import anime from "animejs/lib/anime.es.js"

class IndexPage extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.onLoaderDone = this.onLoaderDone.bind(this)
    }

    state = {
        isLoaderDone: true,
    }

    componentWillMount() {
        if (typeof window !== "undefined") {
            this.setAnimationConfig()
        }
    }

    setAnimationConfig() {
        this.timeline = anime.timeline({
            easing: "easeInOutExpo",
        })

        this.config = {
            opacity: [0, 1],
            duration: 500,
            easing: "easeInOutQuad",
        }
    }

    onLoaderDone() {
        this.setState({
            isLoaderDone: true,
        })
    }

    render() {
        return (
            <View name="home" theme="home">
                <Metas
                    description={
                        "Avoir un projet de site internet, c’est se poser beaucoup de (bonnes) questions. Pour vous aider à faire les bons choix, s’inspirer et mettre votre projet sur de bons rails, voici le bon site. On commence ?"
                    }
                    image={""}
                    shouldIndex={true}
                />

                {!this.state.isLoaderDone && (
                    <Panel name="intro">
                        <LoaderIntro callback={this.onLoaderDone} />
                    </Panel>
                )}

                <CanvasBackground
                    timeline={this.timeline}
                    timelineConfig={this.config}
                />
                <PanelTitle
                    timeline={this.timeline}
                    timelineConfig={this.config}
                />
                <PanelLaunch
                    timeline={this.timeline}
                    timelineConfig={this.config}
                />
                <PanelWhy
                    timeline={this.timeline}
                    timelineConfig={this.config}
                />

                <div className={`view__bottom`}>
                    <Footer />
                </div>
            </View>
        )
    }
}

export default IndexPage

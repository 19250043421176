import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelWhy.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import LinkPrimary from "~components/Links/LinkPrimary"

class PanelWhy extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.panel = React.createRef()
    }

    componentDidMount() {
        this.props.timeline.add(
            {
                targets: this.panel.current,
                opacity: [0, 1],
                duration: 500,
                easing: "easeInOutQuad",
            },
            2300
        )
    }

    render() {
        return (
            <div
                className={`${CommonStyles.PanelWhite} ${Styles.PanelWhy}`}
                ref={this.panel}
            >
                <Row>
                    <Cell start="0" end="10" startTl="0" endTl="11">
                        <div
                            className={`${CommonStyles.PanelWhite__inner} ${Styles.PanelWhy__inner}`}
                        >
                            <div className={CommonStyles.PanelWhite__top}>
                                <p className="teasing-2">
                                    Et si on reprenait soin de nos sites web ?
                                    Parfois délaissés au profit des réseaux
                                    sociaux, soumis à une vision trop technique
                                    ou fonctionnelle, les sites sont pourtant la
                                    clé de voute de la relation entre une marque
                                    et ses publics, la base d’une communication
                                    cohérente et tenue dans le temps.
                                </p>
                            </div>
                            <div className={CommonStyles.PanelWhite__bottom}>
                                <LinkPrimary
                                    to="/pourquoi-le-bon-site"
                                    title="Pourquoi cette démarche ?"
                                />
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelWhy

import React, { Component } from "react"
import { Link } from "gatsby"

// Styles
import Styles from "./LinkPrimary.module.styl"
import Icon from "~icons/Icon"

class LinkPrimary extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const classNames = `${Styles.LinkPrimary} link__item ${this.props.extraClassNames}`

        return (
            <Link
                to={this.props.to}
                className={classNames}
                state={this.props.state}
                title={this.props.title}
            >
                <span className={Styles.LinkPrimary__label}>
                    {this.props.title}
                </span>
                <div className={`${Styles.LinkPrimary__icon}`}>
                    {/*<Icon name="round-arrow-right" width={32} height={32} fill="#000" />*/}
                </div>
            </Link>
        )
    }
}

export default LinkPrimary

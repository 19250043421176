import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelLaunch.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import Icon from "~icons/Icon"
import LinkPrimary from "~components/Links/LinkPrimary"

class PanelLaunch extends Component {
    constructor(props) {
        super(props)

        this.panel = React.createRef()
    }

    componentDidMount() {
        this.props.timeline.add(
            {
                targets: this.panel.current,
                duration: 500,
                opacity: [0, 1],
                easing: "easeInOutQuad",
            },
            2000
        )
    }

    render() {
        return (
            <div
                className={`${CommonStyles.PanelWhite} ${Styles.PanelLaunch}`}
                ref={this.panel}
            >
                <Row>
                    <Cell start="2" end="12" startTl="1" endTl="12">
                        <div className={CommonStyles.PanelWhite__inner}>
                            <div
                                className={`${CommonStyles.PanelWhite__top} ${Styles.PanelLaunch__top}`}
                            >
                                <h2 className="teasing-2">
                                    Avoir un projet de site internet, c’est se
                                    poser beaucoup de (bonnes) questions. Pour
                                    vous aider à faire les bons choix,
                                    s’inspirer et mettre votre projet sur de
                                    bons rails, voici le bon site. On commence ?
                                </h2>
                            </div>
                            <div
                                className={`${CommonStyles.PanelWhite__body} ${Styles.PanelLaunch__body}`}
                            >
                                <Link
                                    className={`${Styles.PanelLaunch__btn} btn--huge`}
                                    to="/questions/1"
                                    aria-label={`Tester votre projet !`}
                                >
                                    <div
                                        className={
                                            Styles.PanelLaunch__btn__left
                                        }
                                    >
                                        <span
                                            className={`btn__label ${Styles.PanelLaunch__btn__label}`}
                                        >
                                            Tester votre projet !
                                        </span>
                                        <span
                                            className={`btn__sublabel ${Styles.PanelLaunch__btn__sublabel}`}
                                        >
                                            14 questions, 3 minutes
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            Styles.PanelLaunch__btn__right
                                        }
                                    >
                                        <Icon
                                            name="big-arrow-right"
                                            width={48}
                                            height={48}
                                            fill="#000"
                                        />
                                        <Icon
                                            name="big-arrow-right"
                                            width={48}
                                            height={48}
                                            fill="#000"
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div
                                className={`${CommonStyles.PanelWhite__bottom} ${Styles.PanelLaunch__bottom}`}
                            >
                                <LinkPrimary
                                    to="sites"
                                    title="Les 12 modèles de site"
                                />
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelLaunch

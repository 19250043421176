import React, { Component } from "react"

// Styles
import Styles from "./PanelTitle.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"

class PanelTitle extends Component {
    constructor(props) {
        super(props)

        this.panelIntro = React.createRef()
    }

    componentDidMount() {
        this.props.timeline.add(
            {
                targets: this.panelIntro.current,
                opacity: [0, 1],
                duration: 1000,
                delay: 200,
                easing: "easeInOutExpo",
            },
            0
        )

        this.props.timeline.add(
            {
                targets: this.panelIntro.current,
                translateY: ["10rem", 0],
                duration: 1000,
                easing: "easeInOutExpo",
            },
            1000
        )
    }

    render() {
        return (
            <div className={Styles.PanelTitle} ref={this.panelIntro}>
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelTitle__inner}>
                            <h1
                                className={`heading-huge ${Styles.PanelTitle__title}`}
                            >
                                <div className={Styles.PanelTitle__item}>
                                    Le bon site
                                </div>
                                {/*
                                <div className={`${Styles.PanelTitle__item} ${Styles.PanelTitle__item__last}`}>
                                    en 2020
                                </div>
                                */}
                            </h1>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelTitle
